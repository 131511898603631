import { ClipLoader } from "react-spinners"

interface Props {
    className?: string
}
export default function FullPageLoading({className = ''}: Props) {
    return (
        <div className={`flex w-full h-full bg-slate-100 items-center justify-center ${className}`}>
            <ClipLoader
                loading
                size={45}
                className="!border-2 !border-secondary-blue !border-b-2 !border-b-transparent"
            />
        </div>
    )
}
