import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { transformErrorResponse } from "utils"
import {
    UpdateCompanyProps,
    Company,
    CreateCompanyResponse,
    UpdateCompanyResponse,
    CompanyIndexItem,
    CompanyState,
    SendContributorInvitationProps,
    AcceptContributorInvitationProps,
} from "./types"
import { ENV } from "config"
import { baseQueryWithReauth } from "api/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/companies`})

export const companiesAPI = createApi({
    reducerPath: 'companiesAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['companies'],
    endpoints: (builder) => ({
        getCompany: builder.query<Company, string>({
            query: (id) => ({url: `/${id}`}),
            providesTags: (result, error, id) => [{type: 'companies', id}],
            transformErrorResponse,
        }),
        getCompanyIndex: builder.query<CompanyIndexItem, string>({
            query: (accountId) => ({url: `?include_connection_states=true&account_id=${accountId}`}),
            providesTags: () => [{type: 'companies', id: 'accounts'}],
            transformErrorResponse,
            transformResponse: (response: {items: CompanyIndexItem[]}) =>
                response.items.filter(({state}) => state === CompanyState.Activated)?.[0]
        }),
        createCompany: builder.mutation<Company, string>({
            query: (account_id) => ({method: 'POST', body: {event_type: 1, account_id}}),
            transformResponse: ({company}: CreateCompanyResponse) => company,
            transformErrorResponse,
        }),
        updateCompany: builder.mutation<Company, UpdateCompanyProps>({
            query: (body) => ({
                url: `/${body.id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (response, error, {id}) => [
                {type: 'companies', id},
                {type: 'companies', id: 'accounts'},
            ],
            transformResponse: ({company}: UpdateCompanyResponse) => company,
            transformErrorResponse,
        }),
        sendContributorInvitation: builder.mutation<void, SendContributorInvitationProps>({
            query: ({companyId, ...body}) => ({
                url: `/${companyId}/invitations`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (response, error, {companyId}) => [
                {type: 'companies', id: companyId}
            ],
            transformErrorResponse,
        }),
        acceptContributorInvitation: builder.mutation<void, AcceptContributorInvitationProps>({
            query: ({invitationId, ...body}) => ({
                url: `/invitations/${invitationId}/response`,
                method: 'POST',
                body,
            }),
            transformErrorResponse,
        }),
    }),
})

export const {
    useGetCompanyQuery,
    useLazyGetCompanyQuery,
    useGetCompanyIndexQuery,
    useUpdateCompanyMutation,
    useCreateCompanyMutation,
    useAcceptContributorInvitationMutation,
    useSendContributorInvitationMutation,
} = companiesAPI
