import mixpanel, {Config} from "mixpanel-browser"
import { ENV } from "config"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { authenticationState } from "redux/slices/authentication"

const mixpanelConfig: Partial<Config> = {
    debug: true,
    persistence: 'localStorage',
    ignore_dnt: true,
}

export default function Mixpanel() {
    const {credentials} = useSelector(authenticationState)

    useEffect(() => {
        mixpanel.init(ENV.MIXPANEL_TOKEN, mixpanelConfig)
    }, [])

    useEffect(() => {
        if (credentials) {
            mixpanel.identify(credentials.sub)
        }
    }, [credentials])

    return null
}
