import { persistor, store } from "./redux/store"
import { Provider } from "react-redux"
import Router from "./router/Router"
import { Suspense } from "react"
import { PersistGate } from "redux-persist/integration/react"
import ErrorScreen from "components/ErrorScreen"
import { ErrorBoundary } from "react-error-boundary"
import Mixpanel from "components/Mixpanel/Mixpanel"
import Toast from "components/Toast"

function App() {
    return (
        <ErrorBoundary FallbackComponent={ErrorScreen}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Suspense fallback={<>Loading ...</>}>
                        <Router />
                    </Suspense>
                    <Toast />
                    <Mixpanel />
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    )
}

export const createApp = () => {
    return <App />
}
