import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { RootState } from 'redux/store'

export interface UserData {
    accountId: string | null
    companyTimers: Record<string, number>
    companyLastAccessed: Record<string, string>
}

export interface GlobalPersistedState {
    [userId: string]: UserData
}

const initialUserData: UserData = {
    accountId: null,
    companyTimers: {},
    companyLastAccessed: {},
}

const globalPersisted = createSlice({
    name: 'globalPersisted',
    initialState: {} as GlobalPersistedState,
    reducers: {
        setAccountId: {
            reducer(state, action: PayloadAction<{ userId: string, accountId: string | null }>) {
                const { userId, accountId } = action.payload
                if (!state[userId]) state[userId] = { ...initialUserData }
                state[userId].accountId = accountId
            },
            prepare(accountId: string | null) {
                return {
                    payload: { userId: 'placeholder', accountId }
                }
            }
        },
        setCompanyTimer: {
            reducer(state, action: PayloadAction<{ userId: string, companyId: string }>) {
                const { userId, companyId } = action.payload
                if (!state[userId]) state[userId] = { ...initialUserData }
                const expirationTime = Date.now() + 10 * 60 * 1000 // 10 minutes from now
                state[userId].companyTimers[companyId] = expirationTime
            },
            prepare(companyId: string) {
                return {
                    payload: { userId: 'placeholder', companyId }
                }
            }
        },
        clearCompanyTimer: {
            reducer(state, action: PayloadAction<{ userId: string, companyId: string }>) {
                const { userId, companyId } = action.payload
                if (state[userId]) {
                    delete state[userId].companyTimers[companyId]
                }
            },
            prepare(companyId: string) {
                return {
                    payload: { userId: 'placeholder', companyId }
                }
            }
        },
        setCompanyLastAccessed: {
            reducer(state, action: PayloadAction<{ userId: string, companyId: string }>) {
                const { userId, companyId } = action.payload
                if (!state[userId]) state[userId] = { ...initialUserData }
                const lastAccessed = dayjs().toISOString()
                state[userId].companyLastAccessed[companyId] = lastAccessed
            },
            prepare(companyId: string) {
                return {
                    payload: { userId: 'placeholder', companyId }
                }
            }
        }
    }
})

export const {
    setCompanyTimer,
    clearCompanyTimer,
    setAccountId,
    setCompanyLastAccessed,
} = globalPersisted.actions

export default globalPersisted.reducer

const selectCurrentUserId = (state: RootState): string | undefined => 
    state.authentication.credentials?.sub

export const selectCompanyTimer = createSelector(
    [
        selectCurrentUserId,
        (state: RootState) => state.globalPersisted,
        (_: RootState, companyId: string | undefined) => companyId,
    ],
    (userId, globalPersisted, companyId): number | undefined => {
        if (!userId || !companyId) return undefined
        return globalPersisted[userId]?.companyTimers[companyId]
    }
)

export const selectIsCompanyTimerComplete = createSelector(
    [
        selectCurrentUserId,
        (state: RootState) => state.globalPersisted,
        (_: RootState, companyId: string | undefined) => companyId,
    ],
    (userId, globalPersisted, companyId): boolean => {
        if (companyId === undefined || !userId) {
            return false
        }

        if (!globalPersisted[userId] || !globalPersisted[userId].companyTimers[companyId]) {
            return true
        }
        
        const expirationTime = globalPersisted[userId].companyTimers[companyId];
        return Date.now() >= expirationTime
    }
)

export const selectCompanyLastAccessed = createSelector(
    [
        selectCurrentUserId,
        (state: RootState) => state.globalPersisted,
        (_: RootState, companyId: string) => companyId,
    ],
    (userId, globalPersisted, companyId): string | undefined => {
        if (!userId) return undefined
        return globalPersisted[userId]?.companyLastAccessed[companyId]
    }
)

export const selectCompaniesLastAccessed = createSelector(
    [selectCurrentUserId, (state: RootState) => state.globalPersisted],
    (userId, globalPersisted): Record<string, string> => {
        if (!userId) return {}
        return globalPersisted[userId]?.companyLastAccessed || {}
    }
)

export const selectAccountId = createSelector(
    [selectCurrentUserId, (state: RootState) => state.globalPersisted],
    (userId, globalPersisted): string | null => {
        if (!userId) return null
        return globalPersisted[userId]?.accountId || null
    }
)