import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { Mutex } from "async-mutex"
import { getTokens } from "./storage"
import { isJWTExpired, willJWTExpireInNextMinute } from "./token"
import axios, { AxiosResponse } from "axios"
import { ENV } from "config"
import { logout, receiveTokens } from "redux/slices/authentication"

const mutex = new Mutex()

type BaseQueryWithReauth = (
    baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
    args: string | FetchArgs,
    api: any,
    extraOptions: {signal?: AbortSignal},
) => ReturnType<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>>

const retryTokenRefresh = async (
    baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
    args: FetchArgs,
    api: any,
    extraOptions: {signal?: AbortSignal},
    retryCount: number = 0
): Promise<any> => {
    if (retryCount >= 3) {
        return {
            error: {
                status: 'CUSTOM_ERROR',
                error: 'Failed to refresh token after multiple attempts.',
            } as FetchBaseQueryError,
        }
    }

    try {
        return await executeTokenRefresh(baseQuery, args, api, extraOptions)
    } catch (error: any) {
        if (error.error?.status === 'CUSTOM_ERROR') {
            return error
        } else {
            return await retryTokenRefresh(baseQuery, args, api, extraOptions, retryCount + 1)
        }
    }
}

const executeTokenRefresh = async (
    baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
    args: FetchArgs,
    api: any,
    extraOptions: {signal?: AbortSignal}
) => {
    const release = await mutex.acquire()
    const {refreshToken} = await getTokens()

    try {
        const response: AxiosResponse = await axios.post(
            `${ENV.ALEXIARES_HOST_URL}/users/tokenrefresh`,
            {
                refresh_token: refreshToken,
                grant_type: 'refresh_token',
            },
        )

        await api.dispatch(receiveTokens(response.data))
        args.headers = {...args.headers, Authorization: `Bearer ${response.data.access_token}`}
        release()
        return baseQuery(args, api, extraOptions)
    } catch (error: any) {
        release()
        if (error.response?.status === 401) {
            api.dispatch(logout())
            return {
                error: {
                    status: 'CUSTOM_ERROR',
                    error: 'Both access and refresh tokens are expired. User must reauthenticate.',
                } as FetchBaseQueryError,
            }
        } else {
            throw error
        }
    }
}

export const baseQueryWithReauth: BaseQueryWithReauth = async (
    baseQuery,
    args,
    api,
    extraOptions
) => {
    await mutex.waitForUnlock()
    const {accessToken, refreshToken} = await getTokens()

    if (isJWTExpired(accessToken)) {
        if (isJWTExpired(refreshToken)) {
            api.dispatch(logout())
            return {
                error: {
                    status: 'CUSTOM_ERROR',
                    error: 'Both access and refresh tokens are expired. User must reauthenticate.',
                } as FetchBaseQueryError,
            }
        }
        return await retryTokenRefresh(baseQuery, args as FetchArgs, api, extraOptions)
    }

    if (willJWTExpireInNextMinute(accessToken)) {
        return await retryTokenRefresh(baseQuery, args as FetchArgs, api, extraOptions)
    } else {
        if (typeof args !== 'string') {
            args.headers = {...args.headers, Authorization: `Bearer ${accessToken}`}
        }
        return baseQuery(args, api, extraOptions)
    }
}
