import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'

export interface GlobalSessionState {
    isConversationsSidebarVisible: boolean
    pendingMessage: string | null,
    activeConversationIds: Record<string, string | null>,
}

const globalSession = createSlice({
    name: 'globalSession',
    initialState: {
        isConversationsSidebarVisible: false,
        pendingMessage: null,
        activeConversationIds: {},
    } as GlobalSessionState,
    reducers: {
        setIsConversationsSidebarVisible: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isConversationsSidebarVisible: action.payload,
        }),
        setPendingMessage: (state, action: PayloadAction<string | null>) => ({
            ...state,
            pendingMessage: action.payload,
        }),
        setActiveConversationId: (state, action: PayloadAction<{companyId: string, conversationId: string | null}>) => ({
            ...state,
            activeConversationIds: {
                ...state.activeConversationIds,
                [action.payload.companyId]: action.payload.conversationId,
            },
        }),
    },
})

export const {
    setIsConversationsSidebarVisible,
    setPendingMessage,
    setActiveConversationId,
} = globalSession.actions
export default globalSession.reducer
export const globalSessionState = (state: RootState) => state.globalSession
