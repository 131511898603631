import { ConnectionState } from "api/connections/types"

export interface Company {
    id: string
    state: CompanyState
    account_id: string
    name: string
    contributors: Contributor[]
    industries: Industry[]
    website: string
    contributor_invitations: ContributorInvitation[]
    pending_connection_names: string[]
    users_with_access: UsersWithAccess[]
}

export interface UsersWithAccess {
    id: string
    first_name: string
    last_name: string
    email: string
    confirmed_at: string
}

export interface Contributor {
    id: string
    first_name: string
    last_name: string
    email: string
    confirmed_at: string | null
}

export interface Industry {
    id: number
    name: string
    group_id: number
}

export const enum ContributorInvitationState {
    /**
     * The invitation _HAS NOT_ been sent yet. This is the state of the invitation
     * prior to the company being activated.
     */
    Unsent = -1,
    /**
     * The invitation was sent and has not yet been responded to.
     */
    Sent = 0,
    /**
     * The invitation was accepted.
     */
    Accepted = 1,
    /**
     * The invitation was declined.
     */
    Declined = 2,
    /**
     * The invitation was rescinded.
     */
    Rescinded = 3,
    /**
     * The invitation was resent.
     */
    Resent = 4,
    /**
     * The invitation has expired. This is a synthetic state, and is computed
     * based upon the date.
     */
    Expired = 5,
}
export interface ContributorInvitation {
    id: string
    company_id: string
    invitee_email: string
    state: ContributorInvitationState
    expires_at: string
    sent_date: string
    connections_to_authenticate: string[]
}

export type UpdateCompanyProps = {
    id: string
    name?: string
    industries_to_add?: number[]
    industries_to_remove?: number[]
    website?: string
    commit_value?: boolean
}

export interface CompanyIndexItem {
    id: string
    state: CompanyState
    name: string
    industries: Industry[]
    extras: {
        financial_connection_states: {
            states: ConnectionState[]

            // TODO figure out what this is
            status: 'active' | any
        }
    }
    
}

export enum CompanyState {
    Created = 0,
    Activated = 1,
    Canceled = 2,
    Archived = 3,
}

export interface EventType {
    id: number
    name: string
    description: string
    is_enabled: boolean
}

export interface CreateCompanyResponse {
    company: Company
}

export interface UpdateCompanyResponse {
    company: Company
}

export interface RemoveCompanyContributorInvitationProps {
    company_id: string
    invitation_id: string
}

export interface GetCompanyContributorInvitationsResponse {
    companies: {
        account_id: string
        invitations: ContributorInvitation[]
        index_item: CompanyIndexItem
    }[]
}

export interface SendContributorInvitationProps {
    companyId: string
    invitee_email: string
    connections_to_authenticate: string[]
}

export interface AcceptContributorInvitationProps {
    invitationId: string
    accept: boolean
}

export interface UpdateCompanyContributorInvitationProps {
    connections_to_add: string[]
    connections_to_remove: string[]
    company_id: string
    invitation_id: string
}
