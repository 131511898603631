import { configureStore, combineReducers, AnyAction, ThunkAction, Action, EnhancedStore } from "@reduxjs/toolkit"
import type { PreloadedState, ThunkDispatch } from "@reduxjs/toolkit"
import { persistStore, persistReducer, PersistConfig, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { accountsAPI } from "api/accounts/accounts"
import { authenticationAPI } from "api/authentication/authentication"
import { billingAPI } from "api/billing/billingAPI"
import { companiesAPI } from "api/companies/companiesAPI"
import { connectionsAPI } from "api/connections/connectionsAPI"
import { conversationsAPI } from "api/conversations/conversations"
import { profileAPI } from "api/profile/profile"
import authentication, { AuthenticationState } from "./slices/authentication"
import globalPersisted, { GlobalPersistedState } from "./slices/globalPersisted"
import globalSession, { GlobalSessionState } from "./slices/globalSession"
import { userIdMiddleware } from "./middleware/userIdMiddleware"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

interface AppState {
    authentication: AuthenticationState
    globalPersisted: GlobalPersistedState
    globalSession: GlobalSessionState
    [accountsAPI.reducerPath]: ReturnType<typeof accountsAPI.reducer>
    [authenticationAPI.reducerPath]: ReturnType<typeof authenticationAPI.reducer>
    [billingAPI.reducerPath]: ReturnType<typeof billingAPI.reducer>
    [companiesAPI.reducerPath]: ReturnType<typeof companiesAPI.reducer>
    [connectionsAPI.reducerPath]: ReturnType<typeof connectionsAPI.reducer>
    [conversationsAPI.reducerPath]: ReturnType<typeof conversationsAPI.reducer>
    [profileAPI.reducerPath]: ReturnType<typeof profileAPI.reducer>
}

const persistConfig: PersistConfig<AppState> = {
    key: 'root_v8',
    storage,
    version: 3,
    whitelist: ['globalPersisted'] as (keyof AppState)[],
}

const appReducer = combineReducers<AppState>({
    [accountsAPI.reducerPath]: accountsAPI.reducer,
    [authenticationAPI.reducerPath]: authenticationAPI.reducer,
    [billingAPI.reducerPath]: billingAPI.reducer,
    [companiesAPI.reducerPath]: companiesAPI.reducer,
    [connectionsAPI.reducerPath]: connectionsAPI.reducer,
    [conversationsAPI.reducerPath]: conversationsAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    authentication,
    globalPersisted,
    globalSession,
})

const rootReducer = (state: AppState | undefined, action: AnyAction): AppState => {
    return appReducer(state, action)
}

const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer)

export const createStore = (
    preloadedState?: PreloadedState<AppState>
): EnhancedStore => {
    return configureStore({
        reducer: persistedReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat(
                accountsAPI.middleware,
                authenticationAPI.middleware,
                billingAPI.middleware,
                companiesAPI.middleware,
                connectionsAPI.middleware,
                conversationsAPI.middleware,
                profileAPI.middleware,
                userIdMiddleware,
            ),
        devTools: true,
    })
}

export const store = createStore()
export const persistor = persistStore(store)
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppThunkDispatch = () => useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
