import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { QuickbooksAuthResponse, DisconnectQuickbooksResponse } from "./types"
import { ENV } from "config"
import { baseQueryWithReauth } from "api/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: ENV.CIRCE_HOST_URL})

export const connectionsAPI = createApi({
    reducerPath: 'connectionsAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['connections'],
    endpoints: (builder) => ({
        connectQuickbooks: builder.query<QuickbooksAuthResponse, {companyId: string, isOnboarding: boolean}>({
            query: ({companyId, isOnboarding}) => ({
                url: '/quickbooks/auth',
                params: {company_id: companyId, isOnboarding: isOnboarding.toString()},
            }),
        }),
        disconnectQuickbooks: builder.mutation<DisconnectQuickbooksResponse, string>({
            query: (company_id) => ({
                url: '/quickbooks/disconnect',
                method: 'POST',
                params: {company_id},
            }),
        }),
    }),
})

export const {
    useConnectQuickbooksQuery,
    useDisconnectQuickbooksMutation,
} = connectionsAPI
