import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AuthResponse, Credentials } from "../../api/authentication/types"
import { RootState } from "redux/store"
import { ENV } from "config"
import axios from "axios"
import { authenticationAPI } from "../../api/authentication/authentication"
import { getTokens, revokeTokens, setTokens } from "api/authentication/utils/storage"

export interface AuthenticationState {
    credentials: Credentials | null
    loading: boolean
}

export const authentication = createSlice({
    name: 'authentication',
    initialState: {
        credentials: null,
        loading: true,
    } as AuthenticationState,
    reducers: {
        logout: () => {
            revokeTokens()
            return {
                credentials: null,
                loading: false,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(receiveTokens.fulfilled, (_, action) => ({
                credentials: action.payload,
                loading: false,
            }))
            .addCase(checkRefreshTokenValidity.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(checkRefreshTokenValidity.fulfilled, (_, action) => ({
                credentials: action.payload,
                loading: false,
            }))
            .addCase(checkRefreshTokenValidity.rejected, (state) => ({
                ...state,
                leading: false,
            }))
            .addMatcher(authenticationAPI.endpoints.login.matchPending, (state) => ({
                ...state,
                loading: true,
            }))
            .addMatcher(authenticationAPI.endpoints.login.matchRejected, (state) => ({
                ...state,
                loading: false,
            }))
            .addMatcher(authenticationAPI.endpoints.login.matchFulfilled, (_, action) => ({
                credentials: action.payload,
                loading: false,
            }))
            .addMatcher(authenticationAPI.endpoints.signUp.matchFulfilled, (_, action) => ({
                credentials: action.payload,
                loading: false,
            }))
    },
})

export const receiveTokens = createAsyncThunk(
    'authentication/receiveTokens',
    async (authResponse: AuthResponse) => await setTokens(authResponse)
)

export const checkRefreshTokenValidity = createAsyncThunk<
    Credentials,
    void,
    {
        state: RootState
        rejectValue: string
    }
>(
    'authentication/checkRefreshTokenValidity',
    async (_, { rejectWithValue }) => {
        try {
            const { refreshToken } = await getTokens()
            if (!refreshToken) {
                return rejectWithValue('No refresh token available')
            }

            const response = await axios.post(`${ENV.ALEXIARES_HOST_URL}/users/tokenrefresh`, {
                refresh_token: refreshToken,
                grant_type: 'refresh_token',
            })

            const newCredentials = await setTokens(response.data)
            return newCredentials
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                return rejectWithValue(error.response.data.message || 'Failed to refresh token')
            }
            return rejectWithValue('An unexpected error occurred')
        }
    }
)

export const {logout} = authentication.actions
export const authenticationState = (state: RootState) => state.authentication
export default authentication.reducer
