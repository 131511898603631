import { Middleware } from 'redux'
import { RootState } from 'redux/store'

export const userIdMiddleware: Middleware<{}, RootState> = store => next => action => {
    if (action.type.startsWith('globalPersisted/') && action.payload && 'userId' in action.payload) {
        const userId = store.getState().authentication.credentials?.sub
        if (userId) {
            action.payload.userId = userId
        }
    }
    return next(action)
}
