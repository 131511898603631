import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
    Account,
    MemberInvitationResponse,
    AcceptAccountInvitationProps,
    AccountIndexItem,
    RescindInvitationProps,
    SendMemberInvitationProps,
    GetAccountsResponse,
} from "./types"
import { transformErrorResponse } from "utils"
import { ENV } from "config"
import { baseQueryWithReauth } from "api/authentication/utils/query"

const baseQuery = fetchBaseQuery({baseUrl: `${ENV.ALEXIARES_HOST_URL}/accounts`})

export const accountsAPI = createApi({
    reducerPath: 'accountsAPI',
    baseQuery: (...args) => baseQueryWithReauth(baseQuery, ...args),
    tagTypes: ['accounts'],
    endpoints: (builder) => ({
        getAccount: builder.query<Account, string>({
            query: (id) => ({url: `/${id}`}),
            providesTags: (result, error, id) => [{type: 'accounts', id}],
            transformErrorResponse,
        }),
        getAccounts: builder.query<AccountIndexItem[], void>({
            query: () => ({method: 'GET'}),
            providesTags: [{type: 'accounts', id: 'list'}],
            transformResponse: ({accounts}: GetAccountsResponse) => accounts,
            transformErrorResponse,
        }),
        sendInvite: builder.mutation<MemberInvitationResponse, SendMemberInvitationProps>({
            query: ({account_id, ...body}) => ({
                url: `/${account_id}/invitations`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (data) => [{type: 'accounts', id: data?.account_id}],
            transformErrorResponse,
        }),
        rescindInvite: builder.mutation<MemberInvitationResponse, RescindInvitationProps>({
            query: (body) => ({
                url: `/invitations/rescindments`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (data) => [{type: 'accounts', id: data?.account_id}],
            transformErrorResponse,
        }),
        acceptAccountInvitation: builder.mutation<void, AcceptAccountInvitationProps>({
            query: ({accountId, ...body}) => ({
                url: `/${accountId}/invitations/response`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{type: 'accounts', id: 'list'}],
            transformErrorResponse,
        }),
    }),
})

export const {
    useGetAccountQuery,
    useSendInviteMutation,
    useGetAccountsQuery,
    useLazyGetAccountQuery,
    useRescindInviteMutation,
    useAcceptAccountInvitationMutation,
} = accountsAPI
