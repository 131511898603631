import { Suspense, useEffect, LazyExoticComponent, FC, ReactNode, lazy } from 'react'
import { BrowserRouter, useRoutes, Outlet, Navigate, useLocation, RouteObject } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppThunkDispatch } from 'redux/store'
import { publicRoutes, privateRoutes, RouteType } from './routes'
import FullPageLoading from 'components/common/FullPageLoading'
import { authenticationState, checkRefreshTokenValidity, logout } from 'redux/slices/authentication'

const NotFound404 = lazy(() => import('pages/not-found/NotFound'))

const ProtectedRoute: FC<{children: ReactNode}> = ({children}) => {
    const {credentials, loading} = useSelector(authenticationState)
    const location = useLocation()

    if (loading) return <FullPageLoading />
    if (!credentials) return <Navigate to="/login" state={{from: location}} replace />
    return children
}

const SuspenseWrapper: FC<{element: LazyExoticComponent<any>}> = ({element: Element}) => (
    <Suspense fallback={<FullPageLoading />}>
        <Element />
    </Suspense>
)

const createRouteObject = (route: RouteType, isPrivate: boolean = false): RouteObject => {
    const element = <SuspenseWrapper element={route.element} />
    const wrappedElement = isPrivate ? <ProtectedRoute>{element}</ProtectedRoute> : element

    const baseObject: Partial<RouteObject> = {
        element: wrappedElement,
        children: route.children?.map(childRoute => createRouteObject(childRoute, isPrivate))
    }

    if ('index' in route && route.index) {
        return {...baseObject, index: true} as RouteObject
    } else if ('path' in route) {
        return {...baseObject, path: route.path} as RouteObject
    }

    throw new Error('Invalid route configuration')
}

const RouterContent: FC = () => {
    const dispatch = useAppThunkDispatch()

    useEffect(() => {
        dispatch(checkRefreshTokenValidity())
            .unwrap()
            .catch(() => {
                dispatch(logout())
            })
    }, [dispatch]);

    const routeObjects: RouteObject[] = [
        ...publicRoutes.map(route => createRouteObject(route)),
        {
            path: '/',
            element: <Outlet />,
            children: privateRoutes.map(route => createRouteObject(route, true))
        },
        {
            path: '*',
            element: <SuspenseWrapper element={NotFound404} />
        }
    ]

    return useRoutes(routeObjects)
}

const Router: FC = () => (
    <BrowserRouter>
        <RouterContent />
    </BrowserRouter>
)

export default Router
