import ReactDOM from "react-dom/client"
import "./index.css"
import { createApp } from "./App"
import * as Sentry from "@sentry/react"
import { ENV } from "config"

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                ENV.ALEXIARES_HOST_URL,
                ENV.CIRCE_HOST_URL,
                ENV.EULABEIA_HOST_URL,
                ENV.CLIENT_HOST_URL,
                ENV.ZOE_HOST_URL,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: ENV.USE_SENTRY === "true",
    beforeSend(event, hint) {
        const error = hint.originalException as Error
        if (error && error.message && error.message.startsWith("Logged:")) {
            return null
        }
        return event
    },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(createApp())
