import { lazy } from 'react'

export type BaseRouteType = {
    element: React.LazyExoticComponent<any>
    children?: RouteType[]
}

export type IndexRouteType = BaseRouteType & {
    index: true
}

export type PathRouteType = BaseRouteType & {
    path: string
}

export type RouteType = IndexRouteType | PathRouteType

export const publicRoutes: RouteType[] = [
    {
        path: '/',
        element: lazy(() => import('pages/auth/Auth')),
        children: [
            {
                path: 'login',
                element: lazy(() => import('pages/auth/login/Login')),
            },
            {
                path: 'signup',
                element: lazy(() => import('pages/auth/signup/Signup')),
            },
            {
                path: 'signup-confirmation',
                element: lazy(() => import('pages/auth/signup-confirmation/SignupConfirmation')),
            },
            {
                path: 'forgot-password',
                element: lazy(() => import('pages/auth/forgot-password/ForgotPassword')),
            },
            {
                path: 'forgot-password-confirmation',
                element: lazy(() => import('pages/auth/forgot-password-confirmation/ForgotPasswordConfirmation')),
            },
            {
                path: 'reset-password',
                element: lazy(() => import('pages/auth/reset-password/ResetPassword')),
            },
            {
                path: 'reset-password-confirmation',
                element: lazy(() => import('pages/auth/reset-password-confirmation/ResetPasswordConfirmation')),
            },
        ],
    },
    {
        path: 'account-activation',
        element: lazy(() => import('pages/account-activation/AccountActivation')),
    },
    {
        path: 'member-invitation',
        element: lazy(() => import('pages/member-invitation/MemberInvitation')),
    },
    {
        path: 'contributor-invitation',
        element: lazy(() => import('pages/contributor-invitation/ContributorInvitation')),
    },
]

export const privateRoutes: RouteType[] = [
    {
        path: '/',
        element: lazy(() => import('pages/companies/Companies')),
        children: [
            {
                index: true,
                element: lazy(() => import('pages/companies/companies-index/CompaniesIndex')),
            },
            {
                path: 'payment',
                element: lazy(() => import('pages/companies/payment/Payment')),
            },
            {
                path: 'payment-confirmation',
                element: lazy(() => import('pages/companies/payment-confirmation/PaymentConfirmation')),
            },
            {
                path: 'onboarding',
                element: lazy(() => import('pages/companies/onboarding/Onboarding')),
                children: [
                    {
                        index: true,
                        element: lazy(() => import('pages/companies/onboarding/onboarding-index/OnboardingIndex')),
                    },
                    {
                        path: 'add-company',
                        element: lazy(() => import('pages/companies/onboarding/add-company/AddCompany')),
                    },
                    {
                        path: 'authentication',
                        element: lazy(() => import('pages/companies/onboarding/authentication/Authentication')),
                    },
                    {
                        path: 'invitation',
                        element: lazy(() => import('pages/companies/onboarding/invitation/Invitation')),
                    },
                    {
                        path: 'incomplete',
                        element: lazy(() => import('pages/companies/onboarding/incomplete/Incomplete')),
                    },
                ],
            },
        ]
    },
    {
        path: ':companyId',
        element: lazy(() => import('pages/company/Company')),
        children: [
            {
                path: 'conversations',
                element: lazy(() => import('pages/company/conversations/Conversations')),
                children: [
                    {
                        path: ':conversationId',
                        element: lazy(() => import('pages/company/conversations/conversation/Conversation')),
                    },
                    {
                        path: 'library',
                        element: lazy(() => import('pages/company/conversations/library/Library')),
                    },
                    {
                        path: 'ask',
                        element: lazy(() => import('pages/company/conversations/ask/Ask')),
                    },
                ]
            },
            {
                path: 'settings',
                element: lazy(() => import('pages/company/settings/Settings')),
                children: [
                    {
                        path: 'company',
                        element: lazy(() => import('pages/company/settings/company/Company')),
                    },
                    {
                        path: 'connections',
                        element: lazy(() => import('pages/company/settings/connections/Connections')),
                    },
                ]
            },
        ],
    },
    {
        path: 'oauth-complete',
        element: lazy(() => import('pages/oauth-complete/OAuthComplete')),
    },
]
